export default {
  createdAt: 1693718400,
  updatedAt: 1696617378,
  slug: 'scatter-plot-maker',
  heading: 'Scatter Plot Maker',
  title: 'Online Scatter Plot Maker - Free, No Signup',
  description: 'The most robust online scatter plot tool. Upload your Excel and Google Sheets, design, and download your free image in any size - zero signup necessary.',
  shortDescription: 'Create a free scatter plot. Import data from Excel & Google Sheets. Customize then download below.',
  directory: 'Charts',
  directoryUrl: '/charts',
  images: {
    resourceUrl: '/img/pages/scatter-plot-maker/featured-image.webp',
    resourceUrlAlt: 'Scatter plot below a hovering selection of Excel cells',
    twitterPost: '/img/pages/scatter-plot-maker/twitter-post.webp',
    openGraph: '/img/pages/scatter-plot-maker/og-image.webp'
  },
  icon: '/icons/chart-scatter.svg',
  reviews: [],
  articleSections: [{
    name: 'What is a Scatter Plot?',
    id: 'what-is-a-scatter-plot'
  }, {
    name: 'When Should You Yse a Scatter Plot?',
    id: 'when-should-you-use-a-scatter-plot'
  }, {
    name: 'What is an XY Graph?',
    id: 'what-is-an-xy-graph'
  }, {
    name: 'What are the Advantages and Disadvantages of the Scatter Plot?',
    id: 'what-are-the-advantages-and-disadvantages-of-the-scatter-plot'
  }, {
    name: 'What is the Best Graph to Show Correlation?',
    id: 'what-is-the-best-graph-to-show-correlation'
  }, {
    name: 'What Type of Correlation is Suggested by the Scatter Plot?',
    id: 'what-type-of-correlation-is-suggested-by-the-scatter-plot'
  }, {
    name: 'What Types of Data Does a Scatter Plot Require?',
    id: 'what-types-of-data-does-a-scatter-plot-require'
  }, {
    name: 'Scatter Plot Maker Features',
    id: 'scatter-plot-maker-features'
  }, {
    name: 'How to Share Your Scatter Plot',
    id: 'how-to-share-your-scatter-plot'
  }, {
    name: 'Can You Make a Scatter Plot in Google Sheets?',
    id: 'can-you-make-a-scatter-plot-in-google-sheets'
  }, {
    name: 'How do you Create a Scatter Plot in Excel?',
    id: 'how-do-you-create-a-scatter-plot-in-excel'
  }, {
    name: 'How do you Make a Scatter Plot in Google Forms?',
    id: 'how-do-you-make-a-scatter-plot-in-google-forms'
  }, {
    name: 'How do you Make a Scatter Plot with Two Sets of Data?',
    id: 'how-do-you-make-a-scatter-plot-with-two-sets-of-data'
  }, {
    name: 'Why Use a Scatter Plot Instead of a Line Graph?',
    id: 'why-use-a-scatter-plot-instead-of-a-line-graph'
  }, {
    name: 'Why Would You Use a Scatter Plot Instead of a Bar Graph?',
    id: 'why-would-you-use-a-scatter-plot-instead-of-a-bar-graph'
  }]
};