import generalConfig from '@/config/general';
export default {
  createdAt: 1732202645,
  updatedAt: 1701634226,
  slug: 'area-chart-maker',
  heading: 'Area Chart Maker',
  title: 'Online Area Chart Maker - Free, No Signup',
  description: 'Rapidly design sleek area charts with our free, no-sign-up tool. Easily connect data from Excel, Google Sheets, or Google Drive.',
  shortDescription: 'Quickly craft beautiful area charts using this no-sign-up, free to use, online tool.',
  directory: 'Charts',
  directoryUrl: '/charts',
  icon: '/icons/chart-area.svg',
  images: {
    resourceUrl: '/img/pages/area-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Area chart showing company profits for each data next to a spreadsheet selection',
    twitterPost: '/img/pages/area-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/area-chart-maker/og-image.webp'
  },
  reviews: [],
  articleSections: [{
    id: 'what-is-an-area-chart',
    name: 'What is an Area Chart?'
  }, {
    id: 'whats-the-difference-between-an-area-chart-and-a-stacked-area-chart',
    name: 'What’s the Difference Between an Area Chart and a Stacked Area Chart?'
  }, {
    id: 'when-to-use-an-area-chart',
    name: 'When to Use an Area Chart'
  }, {
    id: 'how-to-create-an-area-chart',
    name: 'How to Create an Area Chart'
  }, {
    id: 'how-to-make-an-area-chart-in-excel',
    name: 'How to Make an Area Chart in Excel'
  }, {
    id: 'how-to-make-an-area-chart-in-google-sheets',
    name: 'How to Make an Area Chart in Google Sheets'
  }, {
    id: 'free-area-chart-maker',
    name: `${generalConfig.productName}: Free Area Chart Maker`
  }]
};