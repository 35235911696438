export default {
  createdAt: 1729929145,
  updatedAt: 1733341850,
  slug: 'double-bar-graph-maker',
  heading: 'Double Bar Graph Maker',
  title: 'Online Double Bar Graph Maker - Free, No Signup',
  description: 'Create a double bar chart free. Upload Excel/Google Sheets, customize everything, and create professional data visualization in minutes.',
  shortDescription: 'Free double bar chart maker: upload data, customize the design, and download.',
  directory: 'Charts',
  directoryUrl: '/charts',
  icon: '/icons/chart-double-bar.svg',
  images: {
    resourceUrl: '/img/pages/double-bar-graph-maker/featured-image.webp',
    resourceUrlAlt: 'Double bar chart showing quarterly return comparisions next to spreadsheet icons',
    twitterPost: '/img/pages/double-bar-graph-maker/twitter-post.webp',
    openGraph: '/img/pages/double-bar-graph-maker/og-image.webp'
  },
  reviews: [],
  articleSections: [{
    id: 'what-is-a-double-bar-graph',
    name: 'What Is a Double Bar Graph?'
  }, {
    id: 'what-are-double-bar-graphs-used-for',
    name: 'What Are Double Bar Graphs Used For?'
  }, {
    id: 'how-to-create-a-double-bar-graph',
    name: 'How to Create a Double Bar Graph'
  }, {
    id: 'how-to-make-a-double-bar-graph-in-excel',
    name: 'How to Make a Double Bar Graph in Excel'
  }, {
    id: 'how-to-make-a-double-bar-graph-in-google-sheets',
    name: 'How to Make a Double Bar Graph in Google Sheets'
  }, {
    id: 'free-double-bar-graph-maker',
    name: 'Excelkits: A Free Double Bar Graph Maker'
  }]
};