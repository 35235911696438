import generalConfig from '@/config/general';
export default {
  createdAt: 1692537887,
  updatedAt: 1733957711,
  slug: 'bubble-chart-maker',
  heading: 'Bubble Chart Maker',
  title: 'Online Bubble Chart Maker - Free, No Signup',
  description: 'Create attractive bubble charts in seconds. Customize then download in any size or file format. Upload Excel & Google Sheets. No signup necessary.',
  shortDescription: 'Create a bubble chart with Excel & Google Sheets. Customize then download for free below.',
  directory: 'Charts',
  directoryUrl: '/charts',
  images: {
    resourceUrl: '/img/pages/bubble-chart-maker/featured-image.webp',
    resourceUrlAlt: 'Bubble chart map next to a selection of spreadsheets cells',
    twitterPost: '/img/pages/bubble-chart-maker/twitter-post.webp',
    openGraph: '/img/pages/bubble-chart-maker/og-image.webp'
  },
  icon: '/icons/chart-bubble.svg',
  reviews: [],
  articleSections: [{
    id: 'what-is-a-bubble-chart',
    name: 'What Is a Bubble Chart?'
  }, {
    id: 'why-are-bubble-charts-beneficial',
    name: 'Why Are Bubble Charts Beneficial?'
  }, {
    id: 'when-to-use-a-bubble-chart',
    name: 'When to Use a Bubble Chart'
  }, {
    id: 'how-to-create-a-bubble-chart-online',
    name: 'How to Create a Bubble Chart Online'
  }, {
    id: 'how-to-create-a-bubble-chart-in-google-sheets',
    name: 'How to Create a Bubble Chart in Google Sheets'
  }, {
    id: 'how-to-create-a-bubble-chart-in-excel',
    name: 'How to Create a Bubble Chart in Excel'
  }, {
    id: 'how-to-create-a-bubble-chart-in-powerpoint',
    name: 'How to Create a Bubble Chart in PowerPoint'
  }, {
    id: 'packed-bubble-chart-generator',
    name: 'Packed Bubble Chart Generator'
  }, {
    id: 'free-bubble-chart-maker',
    name: `${generalConfig.productName} Free Bubble Chart Maker`
  }]
};